export const useSmoothScroll = () => {
  const wait = (ms = 1000) =>
    new Promise((res) =>
      setTimeout(() => {
        res(null);
      }, ms)
    );

  const smoothScoll2 = async (id: string) => {
    // navigate and wait

    let element = document.getElementById(id); // Your target element
    const headerOffset = 45;
    if (!element) {
      console.log('element not found');
      await wait(500);
      element = document.getElementById(id);
    }
    if (!element) {
      return console.error('element not found');
    }
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  return { smoothScollToId: smoothScoll2 };
};
