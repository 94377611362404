import React from 'react';
import { motion } from 'framer-motion';
import styled, { ThemeProvider } from 'styled-components';

import MetaTags from '../components/MetaTags';

import { theme } from '../styles/theme';

import Background from '../components/Background';
import Header from '../components/Header2';
import { EVENT_TRACKS } from '../constants/eventTracks';
import { SegmentTracker } from '../domain/Tracker';
import { useSmoothScroll } from '../helpers/useSmoothScroll';
import FooterNew from '../sections/common/FooterWithoutFormFactorHook';
import FaqSection from '../sections/Faq';
import Join from '../sections/Join';
import Companies from '../sections/marketing/Companies';
import data from '../sections/marketing/data';
import LoveIllume from '../sections/marketing/LoveIllume';
import { B2B_PRICING_DATA } from '../sections/pricing/data/B2B_PRICING_DATA';
import PricingSection from '../sections/pricing/PricingSection';
import { getMainAppFullPath } from '../constants/getUrl';

const Layout = styled(motion.div)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 24px;
  position: relative;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    -webkit-backface-visibility: hidden;
  }
`;
const B2B_HREF_ID = 'teams';

const B2BPricingPage = () => {
  const { smoothScollToId } = useSmoothScroll();
  return (
    <ThemeProvider theme={theme}>
      <MetaTags
        title='The Celebration Platform | illume'
        description={
          'From birthdays to just because, illume is reimagining how the world celebrates.'
        }
      />
      <Layout>
        <Background showTopBlob={false} showMiddleBlob={false} />
        <Header
          additionalLinks={[
            {
              text: 'pricing',
              onClick: () => smoothScollToId(B2B_HREF_ID),
            },
          ]}
        />

        <PricingSection
          title=''
          id={B2B_HREF_ID}
          pricings={B2B_PRICING_DATA}
          pricingAction={{
            onClick: (pricing) => {
              const tracker = new SegmentTracker();
              tracker.trackEvent(EVENT_TRACKS.CLICK_B2B_PRICING_PLAN.name, {
                type: pricing.pricingType,
              });

              const pathname = getMainAppFullPath('/create/select-plan');

              const urlWithPricingTypeInSearchParams = new URL(pathname);
              urlWithPricingTypeInSearchParams.searchParams.append(
                'pricingType',
                pricing.pricingType
              );

              return (window.location.href = urlWithPricingTypeInSearchParams.toString());
            },
            name: 'Get Started',
          }}
        />

        <Companies text='illume is trusted and used by organizations around the World' />
        <FaqSection />
        <LoveIllume testimonials={data.testimonials} />
        <Join />
        <FooterNew />
      </Layout>
    </ThemeProvider>
  );
};

export default B2BPricingPage;
